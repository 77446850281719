import React from "react"

export default function Separator({
  width = "80%",
  borderWidth = "1px",
  height = "auto",
  borderColor="",
  paddingBottom = "",
  paddingTop = "",
}) {
  return (
    <div className="divider fbx-cc" style={{ height, paddingBottom, paddingTop }}>
      <div className="divider-separator" style={{ width, borderWidth, borderColor }}></div>
    </div>
  )
}
