import React, { useState, useEffect } from "react"

export default function AnimateBB({ children }) {
  const [bb, setBB] = useState("start")

  useEffect(() => {
    if (bb === "out") {
      setTimeout(() => {
        setBB("hide-start")
      }, 500)
    }
    if (bb === "hide-start") {
      setTimeout(() => {
        setBB("start")
      }, 100)
    }
  }, [bb])
  return (
    <span
      onMouseEnter={() => {
        if (bb !== "start") return
        setBB("full")
      }}
      onMouseLeave={() => {
        if (bb !== "full") return
        setBB("out")
      }}
      role="link"
      className={`bottom-animation-holder`}
      tabIndex={0}
    >
      {children}
      <span className="animathingie-container">
        <span className={`animathingie ${bb}`}></span>
      </span>
    </span>
  )
}
