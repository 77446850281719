import React, { useState } from "react"
import { faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Collapse from "@material-ui/core/Collapse"

export default function Toggle({
  id = "",
  title,
  children,
  noIcon = false,
  className = "",
  expandIcon = null,
  contractedIcon = null,
  expanded = false,
}) {
  const [expand, setExpand] = useState(false)
  React.useEffect(() => {
    setExpand(expanded)
  }, [expanded])
  return (
    <div id={id} className={`g-toggle ${className} ${expand ? "open" : ""}`}>
      <div
        className="control"
        onClick={() => setExpand(!expand)}
        onKeyPress={() => setExpand(!expand)}
        role="button"
        tabIndex={0}
      >
        {!noIcon && !expandIcon && !contractedIcon && (
          <FontAwesomeIcon icon={faCaretUp} className="default-icon" />
        )}
        {!noIcon && expand && !!expandIcon && expandIcon}
        {!noIcon && !expand && !!contractedIcon && contractedIcon}
        {title}
      </div>

      <Collapse in={expand} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  )
}
