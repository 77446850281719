import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons"

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false)

  const ourWindow = typeof window !== "undefined" && window

  const checkScrollTop = () => {
    if (!showScroll && ourWindow.pageYOffset > 400) {
      setShowScroll(true)
    } else if (showScroll && ourWindow.pageYOffset <= 400) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    if (ourWindow) ourWindow.scrollTo({ top: 0, behavior: "smooth" })
  }

  if (ourWindow) ourWindow.addEventListener("scroll", checkScrollTop)

  return (
    <div className="scrollTop">
      <FontAwesomeIcon
        icon={faChevronCircleUp}
        onClick={scrollTop}
        style={{ display: showScroll ? "flex" : "none" }}
        size="2x"
      />
    </div>
  )
}

export default ScrollToTop
