import React from "react"
import { Helmet } from "react-helmet"
import Button from "@material-ui/core/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDollarSign, faComments } from "@fortawesome/free-solid-svg-icons"
import { faCopy } from "@fortawesome/free-regular-svg-icons"
import Fade from "react-reveal/Fade"
import AnimateBB from "../../components/AnimateBB"
import Toggle from "../../components/toggle"
import Layout from "../../components/layout"
import ColoredHeader from "../../components/coloredHeader"
import Separator from "../../components/separator"
import FloatingSearch from "../../components/floatingSearch"
import ScrollToTop from "../../components/ScrollToTop"

import atlas_lg_blackwhite from "../../../static/background_images/atlas_lg_blackwhite.jpg"
import check_clean_circle from "../../../static/images/check_clean_circle.jpg"
import DirectDepositInvitation_EnrollmentForm from "../../../static/documents/DirectDepositInvitation_EnrollmentForm.pdf"
import DirectDeposit_CancellationForm from "../../../static/documents/DirectDeposit_CancellationForm.pdf"
import royalty_check_detail from "../../../static/documents/royalty_check_detail-updated.pdf"
import OwnerInformationUpdateForm from "../../../static/documents/OwnerInformationUpdateForm.pdf"
import Example_2017_1099_MISC from "../../../static/documents/Example_2017_1099_MISC.pdf"

export default function Owners(props) {
  const [allFormsOpen, setAllFormsOpen] = React.useState(false)
  const [allContactsOpen, setAllContactsOpen] = React.useState(false)

  return (
    <Layout>
      <Helmet>
        <title>Owners – EOG Resources, Inc.</title>
      </Helmet>
      <ColoredHeader
        title="COMPANY"
        header="Interest Owners"
        overlayColor1="#d50000"
        overlayColor2="#da3433"
        lineColor="#f95c4f"
        bgImageSRC={atlas_lg_blackwhite}
      />
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch clear />
      </div>
      <section
        className="page owners-page max-width-container"
        style={{
          paddingTop: "85px",
        }}
      >
        <Fade bottom>
          <h1 className="title">Have A Question?</h1>
        </Fade>
        <Separator borderWidth="2px" />
        <h3 className="subtitle">
          Call or email us with your land or royalty ownership questions,
          including royalty check inquiries.
        </h3>
        <AnimateBB>
          <a className="main-phone" href="tel:8334302550">
            833 430 2550
          </a>
        </AnimateBB>
        <AnimateBB>
          <a
            className="main-email"
            href="mailto:landadm_callcenter@eogresources.com"
          >
            landadm_callcenter@eogresources.com
          </a>
        </AnimateBB>
        <div
          style={{ paddingTop: "20px", paddingBottom: "65px" }}
          className="dxce-card"
        >
          <Button
            onClick={() => {
              const ourWindow = typeof window !== "undefined" && window

              setAllContactsOpen(true)
              setTimeout(() => {
                if (ourWindow) {
                  const allFormsElement = document.querySelector("#allcontacts")
                  var rect = allFormsElement.getBoundingClientRect()
                  ourWindow.scrollTo({
                    top: rect.top,
                    behavior: "smooth",
                  })
                }
              }, 350)
            }}
            className="dxce-card-button"
          >
            All Contacts
          </Button>

          <Button
            onClick={() => {
              const ourWindow = typeof window !== "undefined" && window

              setAllFormsOpen(true)
              setTimeout(() => {
                if (ourWindow) {
                  const allFormsElement = document.querySelector("#allforms")
                  var rect = allFormsElement.getBoundingClientRect()
                  ourWindow.scrollTo({
                    top: rect.top,
                    behavior: "smooth",
                  })
                }
              }, 350)
            }}
            className="dxce-card-button"
            style={{ marginLeft: "12px" }}
          >
            All Forms
          </Button>
        </div>
      </section>

      <section className="page owners-page gray">
        <div className="max-width-container dxce-card-holder">
          <div className="owners-card dxce-card">
            <Fade top>
              <FontAwesomeIcon className="bigicon" icon={faDollarSign} />
            </Fade>
            <Fade bottom>
              <h3>EnergyLink (Enverus)</h3>
            </Fade>
            <AnimateBB>
              <a
                href="https://www.energylink.com/contact"
                target="_blank"
                rel="noreferrer"
              >
                <Button className="dxce-card-button">Contact Form</Button>
              </a>
            </AnimateBB>
            <p className="card-content">
              Questions regarding your Online Direct Deposit (ACH) account such
              as technical assistance and password resets.
            </p>
            <div className="cols">
              <div className="col">
                <a
                  href="https://energylink.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="dxce-card-button">Login</Button>
                </a>
              </div>
              <div className="col">
                <a
                  href="https://www.energylink.com/Public/Signup.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button className="dxce-card-button">New Users</Button>
                </a>
              </div>
            </div>
          </div>
          <div className="owners-card">
            <Fade top>
              <FontAwesomeIcon className="bigicon" icon={faComments} />
            </Fade>
            <Fade bottom>
              <h3>Joint Working Interest Partner (JIB)</h3>
            </Fade>
            <AnimateBB>
              <a href="tel:7136516553" className="card-phone">
                713 651 6553
              </a>
            </AnimateBB>
            <AnimateBB>
              <a
                href="mailto:jibinquiry@eogresources.com"
                className="card-email"
              >
                jibinquiry@eogresources.com
              </a>
            </AnimateBB>
            <p className="card-content">
              Questions regarding Joint Interest Billings (JIB).
            </p>
          </div>
          <div className="owners-card">
            <Fade top>
              <FontAwesomeIcon className="bigicon" icon={faCopy} />
            </Fade>
            <Fade bottom>
              <h3>IRS 1099 Reporting</h3>
            </Fade>
            <AnimateBB>
              <a href="tel:8776421099" className="card-phone">
                877 642 1099
              </a>
            </AnimateBB>
            <AnimateBB>
              <a href="mailto:EOGR1099@eogresources.com" className="card-email">
                EOGR1099@eogresources.com
              </a>
            </AnimateBB>
            <p className="card-content">
              Questions regarding 1099 Forms issued by EOG.
            </p>
          </div>
        </div>
      </section>

      <section className="page owners-page last-page max-width-container">
        <h2 className="faq-title">Frequently Asked Questions</h2>
        <Toggle title="The Basics">
          <div role="tabpanel" className="toggle-content">
            <h2>How do I contact EOG Resources?</h2>
            <p>
              The Owner Relations Team is here to answer any questions
              concerning ownership, division orders, lease payments, and revenue
              payments. You can reach this team by calling (833) 430-2550 or
              emailing{" "}
              <a href="mailto:LandAdm_CallCenter@eogresources.com">
                LandAdm_CallCenter@eogresources.com
              </a>
              . All calls are logged and returned typically within two working
              days.
            </p>
            <h2>What is clearing title?</h2>
            <p>
              Clearing title is the process of verifying owners of the surface,
              minerals, working interest and royalty interest in the land. This
              process allows EOG Resources to determine the parties having legal
              right to production and royalties.
            </p>
            <h2>What is a division order?</h2>
            <p>
              A division order is a document detailing your ownership
              information in a particular property or unit. It also authorizes
              distribution of funds according to your ownership decimal. The
              division order does not represent a sale of your property, nor
              does it change your lease terms. When you sign and return the
              division order, you confirm that you agree with the information
              disclosed in the document.
            </p>
            <h2>Where can I get a valuation of my mineral interest?</h2>
            <p>
              EOG Resources does not provide appraisals or valuations for
              owners. You should contact a certified petroleum engineer, a
              royalty management company or broker to obtain the valuation.
            </p>
            <h2>
              Where can I find my owner number (May also display as Account or
              BA number)?
            </h2>
            <p>
              Every owner is assigned a unique owner number that is found on
              division orders, check stubs, and 1099s. Please include your owner
              number in all communications with EOG Resources, including
              voicemail and email.
            </p>
          </div>
        </Toggle>
        <Toggle title="All About Royalty Payments">
          <div role="tabpanel" className="toggle-content">
            <h2>When are royalty checks issued?</h2>
            <p>
              Payments are mailed or deposited electronically into your account
              on or around the 12th of each month. &nbsp;If you are receiving
              payments via mail, please allow 10 business days to receive your
              check.
            </p>
            <h2>What should I do if I do not receive my royalty checks?</h2>
            <p>
              Due to potential postal delays, please allow 10 business days to
              receive your royalty checks. If you still did not receive a
              royalty check after 10 business days from the 12th of the month,
              please contact the Owner Relations Team at (833) 430-2550 or email{" "}
              <a href="mailto:LandAdm_CallCenter@eogresources.com">
                LandAdm_CallCenter@eogresources.com
              </a>
              .&nbsp;
            </p>
            <p>
              Owners will not receive checks if the account balance has not
              reached the minimum payment threshold. EOG Resources has a minimum
              payment policy of the lesser of $25 or the minimum amount required
              by statute or the minimum amount required by your lease. A payment
              will automatically be issued on the month that your account has
              accumulated this minimum required amount. Otherwise, in January of
              each year, you will be issued your accumulated payment as long as
              it totals more than $10.
            </p>
            <h2>Can checks over 6 months still be cashed?</h2>
            <p>
              No, checks over 6 months are expired and cannot be cashed. Please
              call the Owner Relations Team at (833) 430-2550 or email{" "}
              <a href="mailto:LandAdm_CallCenter@eogresources.com">
                LandAdm_CallCenter@eogresources.com
              </a>{" "}
              to have your check reissued. You can also reach the Royalty
              Relations Department toll free at (877) 363-3647, option 2.
            </p>
            <h2>
              Why am I being charged expenses/deductions even though I am not a
              working interest owner?
            </h2>
            <p>
              Some costs associated with selling a product are shared by all
              owners including royalty owners and detailed on the revenue check
              stub. &nbsp;Working interest owners share all those same costs
              plus the cost of exploration, drilling, completing, equipping and
              operating a well.
            </p>
            <h2>Why does my monthly payment amount vary?</h2>
            <p>
              There are various factors that impact your monthly payment such as
              fluctuating commodity prices, normal decline of production
              volumes, and operational issues which may affect the volumes
              produced from a well.
            </p>
            <p>
              Volume or price revisions may necessitate adjustments to your
              payment. If you were either overpaid or underpaid, adjustments are
              necessary to correct the previous payment. It is a good idea to
              keep your check detail for future reference, in the event
              adjustments occur.
            </p>
            <h2>When are royalty payments suspended?</h2>
            <p>
              For the owner’s protection, payments are held in suspense because
              of the transfer of a property interest from one owner to another,
              notice of death, lack of address, and other legal issues. Amounts
              due are accumulated and are released when the matter in question
              has been resolved, and EOG has received and processed all related
              documents.
            </p>
            <h2>Why is Backup Withholding deducted from my check?</h2>
            <p>
              You are required to provide a valid Tax Identification Number to
              EOG for tax reporting and identification purposes. &nbsp;U.S.
              residents must furnish a completed IRS Form W-9 and foreign
              residents must provide Form W-8.&nbsp; If the appropriate Form is
              not provided, the Internal Revenue Service requires withholding a
              statutory percentage of your payment until this information is
              received. &nbsp;Please refer to the IRS website for the most
              updated forms and instructions.&nbsp; Also, backup withholding is
              required by some states.
            </p>
            <p>
              <a
                href="https://www.irs.gov/forms-instructions"
                target="_blank"
                rel="noreferrer"
              >
                IRS Forms, Instructions &amp; Publications
              </a>
            </p>
          </div>
        </Toggle>
        <Toggle title="Direct Deposit, ACH and EnergyLink">
          <div role="tabpanel" className="toggle-content">
            <h2>What is Direct Deposit?</h2>
            <p>
              Direct Deposit is a fast, safe and efficient means of delivering
              your royalty payments using an Electronic Funds Transfer (EFT).
              &nbsp;An EFT is an automation process that permits funds to be
              directly deposited to your financial institution (bank, credit
              union, etc.). &nbsp;The Automated Clearing House (ACH) payment
              system is used to process these direct payments. Currently, EOG
              only accepts U.S. banking institution accounts for Direct Deposit.
            </p>
            <h2>How do I set up Direct Deposit (ACH)?</h2>
            <ol>
              <li>
                Verify that your U.S. banking institution offers Direct Deposit
                via Electronic Funds Transfer.
              </li>
              <li>
                Complete the{" "}
                <a
                  href={DirectDepositInvitation_EnrollmentForm}
                  target="_blank"
                  rel="noreferrer"
                >
                  Direct Deposit Enrollment Form
                </a>{" "}
                (DDEF), following all instructions. If all requirements listed
                are not met, your DDEF will not be accepted.
              </li>
              <li>
                Return the completed DDEF and voided check or bank account
                verification letter to:
                <p>
                  EOG Resources, Inc.
                  <br />
                  Land Administration – ACH
                  <br />
                  P.O. Box 4362
                  <br />
                  Houston, TX 77210-4362
                </p>
              </li>
              <li>
                Please allow up to three payment cycles for the first Electronic
                Funds Transfer to begin. You will receive a physical check via
                USPS mail during this processing time.
              </li>
            </ol>
            <h2>What if my Direct Deposit (ACH) information changes?</h2>
            <ol>
              <li>
                Fill out a new{" "}
                <a
                  href={DirectDepositInvitation_EnrollmentForm}
                  target="_blank"
                  rel="noreferrer"
                >
                  Direct Deposit Enrollment Form
                </a>{" "}
                (DDEF) following all instructions. Make certain to select the
                ‘Request Change’ box. If all requirements listed are not met,
                your DDEF will not be accepted.
              </li>
              <li>
                Return the completed DDEF and voided check or bank account
                verification letter to:
                <p>
                  EOG Resources, Inc.
                  <br />
                  Land Administration – ACH
                  <br />
                  P.O. Box 4362
                  <br />
                  Houston, TX 77210-4362
                </p>
              </li>
              <li>
                Please allow up to three payment cycles for your change to be
                effective. During this processing time, you will receive a
                physical check in the mail until your Direct Deposit (ACH)
                account is re-established.
              </li>
            </ol>
            <h2>
              How do I terminate Direct Deposit (ACH) and paperless statements?
            </h2>
            <ol>
              <li>
                Complete the{" "}
                <a
                  href={DirectDeposit_CancellationForm}
                  target="_blank"
                  rel="noreferrer"
                >
                  Direct Deposit Cancellation Form (DDCF)
                </a>{" "}
                following all instructions. If all requirements listed are not
                met, your DDCF will not be accepted.
              </li>
              <li>
                Return the completed DDCF to:
                <p>
                  EOG Resources, Inc.
                  <br />
                  Land Administration – ACH
                  <br />
                  P.O. Box 4362
                  <br />
                  Houston, TX 77210-4362
                </p>
              </li>
              <li>
                After your DDCF Form is processed, you will begin receiving your
                check and check stub via mail. Checks are printed and mailed on
                or around the 12th&nbsp;day of each month. Please allow 10
                business days to receive your check.
              </li>
            </ol>
            <h2>What is EnergyLink (Enverus)?</h2>
            <p>
              EnergyLink allows owners to view check detail and payment history
              online. &nbsp;This optional service is available for owners signed
              up for ACH Direct Deposit and requires a valid email address.
            </p>
            <h2>What happened to Owner Relations Connect (Oildex)?</h2>
            <p>
              Owner Relations Connect (Oildex) was acquired by DrillingInfo in
              September of 2018, which then rebranded as Enverus.
            </p>
            <p>
              Enverus has phased out Oildex by moving all its customers to their
              EnergyLink online platform. Only Royalty ACH payment detail for
              2021 forward is available through the EnergyLink web portal.
            </p>
            <p>
              Unfortunately, prior years will not be available on EnergyLink.
              Access to Oildex is no longer available.
            </p>

            <h2>How do I set up my EnergyLink login information?</h2>
            <p>
              Once you are successfully enrolled with Direct Deposit, and only
              after you receive your first payment via ACH, you can register as
              a new user on the EnergyLink webpage. The following link will take
              you to the Energy Link User Setup:{" "}
              <a
                href="https://www.energylink.com/Public/Signup.aspx"
                target="_net"
              >
                https://www.energylink.com/Public/Signup.aspx
              </a>
              .
            </p>
            <p>
              If you are having technical difficulty logging into your account,
              please contact EnergyLink by submitting a web form at{" "}
              <a href="https://www.energylink.com/contact" target="_new">
                https://www.energylink.com/contact
              </a>
              .
            </p>
            <h2>
              What if I have a problem logging into my EnergyLink account?
            </h2>
            <p>
              If you are having technical difficulty logging into your account,
              please contact EnergyLink by submitting a web form at:{" "}
              <a href="https://www.energylink.com/contact" target="_new">
                https://www.energylink.com/contact
              </a>
              .
            </p>
          </div>
        </Toggle>
        <Toggle title="Understanding Your Check">
          <div role="tabpanel" className="toggle-content">
            <h2>What information is on my revenue / royalty check?</h2>
            <p>
              The information detailed on your royalty check includes both EOG’s
              royalty payment for the entire interest, as well as your specific
              ownership interest.
            </p>
            <p>
              The left side columns of the check describe the property and
              information applicable to the entire interest for which EOG remits
              a royalty payment.
            </p>
            <p>
              Information on your specific ownership interest can be found in
              the five columns on the right side of the check detail under the
              “Owner” heading.
            </p>
            <p>
              <a href={royalty_check_detail} target="_blank" rel="noreferrer">
                Download Sample Check with Explanations
              </a>
            </p>
          </div>
        </Toggle>
        <Toggle title="Understanding Your 1099">
          <div role="tabpanel" className="toggle-content">
            <h2>When will I receive my 1099?</h2>
            <p>
              1099s are mailed to the address on file by January 31st of each
              year. Please allow 10 business days to receive your 1099 by mail.
            </p>
            <h2>
              If I am enrolled in Direct Deposit (ACH), will I receive my 1099
              through the EnergyLink website (Enverus)?
            </h2>
            <p>
              All owners will receive their 1099 via mail sent out on January
              31st even if they receive their statements online. 1099s will not
              be available through the Owner Relations Connect website.
            </p>
            <h2>
              I received very few royalty payments, so will I receive a 1099?
            </h2>
            <p>The minimum amounts for 1099s to be automatically issued are:</p>
            <ul>
              <li>Rents – $600</li>
              <li>Royalties – $10</li>
              <li>
                NonEmployee Compensation or Working Interest Before Deductions –
                $600
              </li>
            </ul>
            <p>
              1099s for less than the minimum amounts stated above can be
              requested at (877) 642-1099 or email{" "}
              <a href="mailto:EOGR1099@eogresources.com">
                EOGR1099@eogresources.com
              </a>
              .
            </p>
            <h2>Why doesn’t EOG mail 1099s for Corporate Entities?</h2>
            <p>
              The IRS does not require EOG Resources to issue a 1099 for
              corporations. However, 1099s can be requested at (877) 642-1099 or
              email{" "}
              <a href="mailto:EOGR1099@eogresources.com">
                EOGR1099@eogresources.com
              </a>
              .
            </p>
            <h2>How do I request a new 1099?</h2>
            <p>
              1099s can be requested at (877) 642-1099. Please have your owner
              number ready, and you will be asked to verify your address and
              social security number.
            </p>
            <h2>
              My address has changed, and I did not receive my 1099. How do I
              request a new one?
            </h2>
            <p>
              1099s can be requested at (877) 642-1099. Please have your owner
              number ready, and you will be asked to verify your address and
              social security number.
            </p>
            <p>
              The change of address form can be emailed or faxed to you. Once
              you return the change of address form, a new 1099 will be sent to
              your new address.
            </p>
            <h2>
              Why is my 1099 more than what I received on my royalty checks?
            </h2>
            <p>
              The 1099 tax form shows the GROSS amount before taxes and other
              deductions as required by the IRS. The royalty checks are the NET
              amount after taxes and other deductions. In order to help you
              reconcile this gross amount to the total of your net royalty
              payments received, we include a reconciliation on the upper right
              side of the 1099. The net amount should agree to the total royalty
              payments received.
            </p>
            <p>
              <strong>Example:</strong>
              <br />
              <strong>
                Net Amount = Gross Amount (Form 1099 Box 2) – Other Deductions –
                Taxes
              </strong>
              <br />
              <img src={check_clean_circle} alt="circle" />
            </p>
            <h2>
              The Net does not equal what I received. How do I get the details?
            </h2>
            <p>
              If the Net Amount does not equal the royalty checks you received,
              then you can request your 1099 details by calling (877) 642-1099.
            </p>
            <h2>Why is my NET royalty payment more than my GROSS amount?</h2>
            <p>
              The net amount can sometimes be more than the GROSS amount due to
              reimbursement of Severance Tax or other adjustments.
            </p>
            <h2>
              I received my December royalty check in January. Why is it on my
              1099?
            </h2>
            <p>
              The IRS uses the “Constructive Receipt” doctrine. Because the
              December royalty check was issued in December, the amount is
              appropriately included on the 1099 even if you received the check
              in January.
            </p>
            <h2>
              I had an expired royalty check in the previous year that was
              reissued in the current year. I have already reported that amount
              in the original year it was issued. How do I ensure that amount
              will not be reported again in the current year?
            </h2>
            <p>
              We make every effort to exclude these amounts from your current
              year 1099. In the event that you think we have made an error,
              please contact us at (877) 642-1099 or&nbsp;email us at{" "}
              <a href="mailto:EOGR1099@eogresources.com">
                EOGR1099@eogresources.com
              </a>
              .
            </p>
            <h2>Can you withhold federal taxes from my checks?</h2>
            <p>
              EOG Resources does not withhold federal taxes unless backup
              withholding is required by statute.
              <br />
              Please consult with a tax advisor concerning the payment of
              estimated taxes.
            </p>
            <h2>
              What are the other deductions, and where do I put them on my tax
              form?
            </h2>
            <p>
              Other deductions include your share of expenses incurred to make
              the products marketable, cost to transport to market, and your
              share of the state severance taxes assessed on production. EOG
              Resources is not able to provide any tax advice and encourages all
              owners to seek proper tax assistance in preparing their tax
              returns.
            </p>
            <h2>Why did I receive a 1099-INT?</h2>
            <p>
              You received a 1099-INT because EOG remitted interest to you as
              required by state statutes. If your royalties were not paid as
              required by the state statutes, interest was calculated and
              remitted to you. The interest payment is reflected on your check
              detail as a single line with the ‘IN’ deduct code.
            </p>
          </div>
        </Toggle>
        <Toggle title="Address and Ownership Changes">
          <div role="tabpanel" className="toggle-content">
            <p>
              IMPORTANT: All address and ownership changes must be requested in
              writing.
            </p>
            <h2>Address Change</h2>
            <p>
              To ensure your payments reach you promptly, you should notify us
              of any change in mailing address immediately by filling out our{" "}
              <a
                href={OwnerInformationUpdateForm}
                target="_blank"
                rel="noreferrer"
              >
                Owner Information Update Form
              </a>
              . Be sure to include your owner number, Tax ID/SSN and signature*
              on the form.
            </p>
            <p>
              *If ownership is held jointly, <u>all owners</u> must sign the
              form for the address change to be processed.
            </p>
            <p>Please mail the completed form to the following address:</p>
            <p>
              EOG Resources, Inc.
              <br />
              Attn: Land Administration
              <br />
              P.O. Box 4362
              <br />
              Houston, TX 77210-4362
            </p>
            <h2>Name Change</h2>
            <ul>
              <li>Name changes due to Marriage or Divorce:</li>
            </ul>
            <p>
              Copy of Marriage Certificate or Divorce Decree or other documents
              affecting name, along with a completed IRS Form W-9
            </p>
            <ul>
              <li>Company or Corporation name change:</li>
            </ul>
            <p>
              Certificate of Name Change along with a completed IRS Form W-9
            </p>
            <h2>Ownership Change</h2>
            <ul>
              <li>Death of Owner</li>
            </ul>
            <p>
              The requirements for transferring ownership upon the death of an
              owner varies based on the state in which the property is located
              and the type of ownership.&nbsp; Please contact the Owner
              Relations Team by calling (833) 430-2550 or emailing &nbsp;
              <a href="mailto:LandAdm_CallCenter@eogresources.com">
                LandAdm_CallCenter@eogresources.com
              </a>
              .
            </p>
            <h2>Transfer of Interest</h2>
            <ul>
              <li>Owner sells or conveys full or partial interest:</li>
            </ul>
            <p>
              Copy of the recorded deed/assignment, new owner’s address
              information, and a completed IRS Form W-9
            </p>
            <h2>Transfer to Trust/Partnership</h2>
            <ul>
              <li>
                Recorded conveyance into Trust/Partnership, address information,
                and a completed IRS Form W-9
              </li>
            </ul>
            <h2>Dissolution or Transfer from Trust/Partnership</h2>
            <ul>
              <li>
                Copy of the recorded deed/assignment, new owner’s address
                information, a completed IRS Form W-9, and a copy of the Trust
                Agreement if applicable.
              </li>
            </ul>
            <h2>Change of Trustee</h2>
            <ul>
              <li>
                Legal document evidencing appointment of successor trustee along
                with address information, and a completed IRS Form W-9
              </li>
            </ul>
          </div>
        </Toggle>
        <Toggle
          id="allcontacts"
          title="All Interest Owner Contacts"
          expanded={allContactsOpen}
        >
          <div role="tabpanel" className="toggle-content">
            <h2>Main Number</h2>
            <p>
              The Owner Relations Team is here to answer any questions
              concerning ownership, division orders, lease payments, and revenue
              payments.&nbsp; You can reach this team by calling (833) 430-2550
              or emailing{" "}
              <a href="mailto:LandAdm_CallCenter@eogresources.com">
                LandAdm_CallCenter@eogresources.com
              </a>
              .&nbsp; All calls are logged and returned typically within two
              working days.
            </p>
            <h2>Direct Deposit (ACH)</h2>
            <p>To set up Direct Deposit or make changes</p>
            <p>Toll free (877) 363-3647; option 3 then 2</p>

            <h2>EnergyLink website (Enverus)</h2>
            <p>
              For online account questions, password resets, or technical
              assistance, please contact EnergyLink by submitting a web form at:{" "}
              <a href="https://www.energylink.com/contact" target="_new">
                https://www.energylink.com/contact
              </a>
              .
            </p>

            <h2>1099 Contacts</h2>
            <p>
              (877) 642-1099 or{" "}
              <a href="mailto:EOGR1099@eogresources.com">
                EOGR1099@eogresources.com
              </a>
            </p>
            <h2>Working Interest Partner Inquiries</h2>
            <p>Questions regarding Joint Interest Billings (JIB)</p>
            <p>
              (713) 651-6553 or{" "}
              <a href="mailto:jibinquiry@eogresources.com">
                jibinquiry@eogresources.com
              </a>
            </p>
          </div>
        </Toggle>
        <Toggle
          id="allforms"
          title="All Forms and Documents"
          expanded={allFormsOpen}
        >
          <div role="tabpanel" className="toggle-content">
            <p>
              <a
                href={DirectDepositInvitation_EnrollmentForm}
                target="_blank"
                rel="noreferrer"
              >
                Direct Deposit Enrollment Form
              </a>
            </p>
            <p>
              <a
                href={DirectDeposit_CancellationForm}
                target="_blank"
                rel="noreferrer"
              >
                Direct Deposit Cancellation Form
              </a>
            </p>
            <p>
              <a href={royalty_check_detail} target="_blank" rel="noreferrer">
                Check Detail Example
              </a>
            </p>
            <p>
              <a href={Example_2017_1099_MISC} target="_blank" rel="noreferrer">
                Example Form 1099-MISC
              </a>
            </p>
            <p>
              <a
                href={OwnerInformationUpdateForm}
                target="_blank"
                rel="noreferrer"
              >
                Owner Information Update Form
              </a>
            </p>
            <p>
              <a
                href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                target="_blank"
                rel="noreferrer"
              >
                IRS Form W-9{" "}
              </a>
            </p>
            <p>
              <a
                href="https://www.irs.gov/pub/irs-pdf/fw8ben.pdf"
                target="_blank"
                rel="noreferrer"
              >
                IRS Form W-8{" "}
              </a>
            </p>
          </div>
        </Toggle>
      </section>
      <ScrollToTop />
      <div style={{ marginBottom: 50 }} />
    </Layout>
  )
}
